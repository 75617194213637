<template>
    <div class="auth-page">
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-12 col-md-6 d-none d-md-block">
            <AuthLeft :paragraph="para" :button2="btn" :hideButton="hideButton" />
          </div>
          <div class="col-12 col-md-6">
            <div class="auth-page-right d-flex flex-column justify-content-center">
             
              <div class="auth-page-right-content">
                <div class="heading">
                  <h6>Add Shopify store</h6>
  
                  <p v-if="errorMessage != null" class="error">
                    {{ errorMessage }}
                  </p>
                </div>
                <form @submit.prevent="continued">
                  <div class="form-group">
                    <div class="form-icon">
                      <img src="../assets/images/url.png" alt="" />
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      id="shopify-store-name"
                      placeholder="shopify.myshopify.com"
                      v-model.trim="details.shopifyStoreName"
                      :class="{
                        'is-invalid': $v.details.shopifyStoreName.$error,
                        'is-valid': !$v.details.shopifyStoreName.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.shopifyStoreName.required">Shopify store name is required</span>
                    </div>
                  </div>
                  
                  <button
                    type="submit"
                    class="auth-btn btn-lg btn-secondary w-100 mt-2"
                    @click="continued"
                  >
                    <span v-if="isLoadingArray[0]" class="lds-dual-ring"></span>
                    <span v-else>Continue</span>
                  </button>

                </form>
                <div class="account d-md-none">
                  <router-link to="/Login" class="forgot-password">Login</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { required } from "vuelidate/lib/validators";
  import AuthLeft from "../components/authleft/AuthLeft.vue";
  import axios from "axios";

  export default {
    name: "AddShopifyStore",
    components: {
      AuthLeft,
    },
  
    data() {
      return {
        hideButton: true,
        details: {
          shopifyStoreName: "",
        },
        errorMessage: null,
        isLoadingArray: [],
        para: "Let's add your Shopify Store",
        btn: "Sign In",
      };
    },
  
    validations: {
      details: {
        shopifyStoreName: {
          required,
        },
      },
    },
  
    computed: {
      ...mapGetters(["errors"]),
    },
  
    mounted() {
      this.$store.commit("setErrors", {});
    },
  
    methods: {
      continued() {
        this.errorMessage = null;
        this.$v.$touch();
        
        // Check for validation errors
        if (this.$v.details.shopifyStoreName.$invalid) {
          // Do nothing if the Shopify store name is invalid
          return;
        }

        // Proceed with the API call if the form is valid
        this.$set(this.isLoadingArray, 0, true);

        let userId;
        const registeredUserId = localStorage.getItem("registeredUserId");

        if (registeredUserId) {
          userId = registeredUserId;
        } else {
          userId = localStorage.getItem("loginUserId");
        }

        // Make a request to the API endpoint to validate the Shopify store name
        axios.post(process.env.VUE_APP_API_URL + "addStoreName", {
          storeName: this.details.shopifyStoreName,
          userId: userId,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$set(this.isLoadingArray, 0, false);
            this.$router.push({ name: "InstructionToAddStore" });
          } else if (response.data.statusCode === 409) {
            // Handle invalid store name
            this.errorMessage = response.data.message;
            this.$set(this.isLoadingArray, 0, false);
          }
        })
        .catch((error) => {
          console.error('Error adding store name:', error);
          this.errorMessage = 'An error occurred';
          this.$set(this.isLoadingArray, 0, false);
        });
      },
    },

  };
  </script>
  